<template>
  <div class="new timelinecomment">
    <div class="_title">You</div>
    <div class="flex">
      <div class="my-border">
        <svg
          width="17"
          height="134"
          viewBox="0 0 17 134"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16.0672 62C16.0672 65.5143 12.6646 68.5 8.28362 68.5C3.90263 68.5 0.5 65.5143 0.5 62C0.5 58.4857 3.90263 55.5 8.28362 55.5C12.6646 55.5 16.0672 58.4857 16.0672 62Z"
            fill="#F2F6FA"
            stroke="#EAF1F8"
          />
          <ellipse
            cx="8.00704"
            cy="62.2334"
            rx="4.14181"
            ry="3.5"
            fill="#DEDEED"
          />
          <line
            x1="7.50879"
            y1="74"
            x2="7.50879"
            y2="134"
            stroke="#E3E7EB"
            stroke-width="2"
          />
          <line
            x1="8.10059"
            x2="8.10059"
            y2="50"
            stroke="#E3E7EB"
            stroke-width="2"
          />
        </svg>
      </div>
      <div class="message">
        <div class="flex mb-2">
          <p class="font-bold pr-1">To:</p>
          <!-- <select name="" id="" class="outline-none" multiple v-model="user" @change="checkTargetted">
          <option value="" disabled selected>{{`Select Targetted user`}}</option>
          <option value="ALL">ALL</option>
          <option v-for="user in users" :key="user.id" :value="user.id" >{{`${user.firstName} ${user.lastName}`}}</option>
          </select> -->
          <multiselect
            v-model="selectedUsers"
            placeholder="Choose message receivers"
            class="multiselect outline-none"
            :options="isAllSelected ? emptyOptions : options"
            :multiple="true"
            :taggable="true"
            :close-on-select="false"
            track-by="id"
            @input="checkTargetted"
            @remove="checkRemoved"
            :custom-label="userNames"
          ></multiselect>
        </div>
        <div class="textarea">
          <textarea v-model="value" type="text" name="" id="" />
        </div>
        <div class="action-buttons flex justify-end w-full">
          <button
            @click="$emit('cancel')"
            class="mr-3 flex justify-center place-items-center"
          >
            Cancel
          </button>
          <button
            @click="$emit('save', value)"
            class="primaryb mr-2 flex justify-center place-items-center"
          >
            Comment
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";

export default {
  name: "Notification",
  data: () => ({
    value: "",
    user: "",
    allUserIds: [],
    options: [],
    emptyOptions: [],
    selectedUsers: [],
    isAllSelected: false,
  }),
  components: {
    Multiselect,
  },
  props: {
    users: {
      type: Array,
    },
  },
  methods: {
    checkTargetted(options) {
      this.allUserIds = [];
      for (const i in options) {
        if (options[i].firstName == "ALL" && options[i].lastName == "") {
          this.isAllSelected = true;
          this.users.map((user) => {
            this.allUserIds.push(user.id);
          });
          this.selectedUsers = this.options[0];
          break;
        } else {
          this.isAllSelected = false;
          this.allUserIds.push(options[i].id);
        }
      }
      this.$emit("target", this.allUserIds);
    },

    checkRemoved(option) {
      if (option.firstName == "ALL") {
        this.isAllSelected = false;
      }
    },

    addUserOptions() {
      this.options = [{ id: 0, firstName: "ALL", lastName: "" }, ...this.users];
    },

    userNames({ firstName, lastName }) {
      return `${firstName + ""} ${lastName}`;
    },
  },

  created() {
    this.options = [{ id: 0, firstName: "ALL", lastName: "" }, ...this.users];
  },
};
</script>


<style lang="scss">
.timelinecomment {
  &.new {
    height: 60%;
    .message {
      height: 139px;  
    }
  }
  textarea {
    max-width: 424px;
    width: 100%;
    height: 84px;
    resize: none;
    border: 1px solid #28a4e2;
    margin-bottom: 7px;
    &:focus {
      outline: none;
    }
  }

  .action-buttons {
    button {
      width: 69px;
      height: 24px;
      border-radius: 2px;
      color: #032b3d;
      font-family: Manrope;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      background: #f6f7fb;
      border-radius: 2px;
      &.primaryb {
        background: #28a4e2;
        color: white;
      }
      &:focus {
        outline: none;
      }
    }
  }

  p {
    color: #28a4e2;
  }
}
</style>