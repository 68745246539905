<template>
  <div :id="id" class="timeline-item flex">
    <div class="border-left">
      <div class="normal">
        <svg
          v-if="active"
          width="20"
          height="89"
          viewBox="0 0 20 89"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="10" cy="10" r="9.5" fill="white" stroke="#28A4E2" />
          <circle cx="9.66602" cy="10.3335" r="5" fill="#28A4E2" />
          <line
            x1="9.5"
            y1="24.9966"
            x2="9.5"
            y2="88.0066"
            stroke="#E3E7EB"
            stroke-width="2"
          />
        </svg>

        <svg
          v-else
          width="20"
          height="89"
          viewBox="0 0 20 89"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="10" cy="10" r="9.5" fill="#F2F6FA" stroke="#EAF1F8" />
          <circle cx="9.66602" cy="10.3335" r="5" fill="#DEDEED" />
          <line
            x1="9.5"
            y1="24.9966"
            x2="9.5"
            y2="88.0066"
            stroke="#E3E7EB"
            stroke-width="2"
          />
        </svg>
      </div>
    </div>
    <div class="data">
      <div class="flex" style="height: 75px">
        <div class="image-container">
          <div
            class="image"
            v-if="user.imageUrl"
            :style="`
            background: url(${imageUrl});
            background-position: center;
            background-size: cover;`"
          />
          <Avatar
            v-else
            :fullname="`${user.firstName}` + ' ' + `${user.lastName}`"
            :size="42"
            color="#054D6F"
            class="mt-1 sm:mt-2"
          ></Avatar>
        </div>
        <div class="details">
          <div :class="`_title ${active ? 'active' : ''} `">{{ title }}</div>
          <div class="subtitle">{{ subtitle }}</div>
          <div class="date flex">
            {{ date | timeAgo }}
            <div
              v-if="file"
              class="download ml-auto -mt-1"
              @click="download(file.url)"
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.00042 20.9813C5.63447 20.8764 4.33658 20.3426 3.29208 19.4561C2.24757 18.5696 1.50986 17.3758 1.18428 16.0451C0.858697 14.7144 0.961891 13.3148 1.47913 12.0462C1.99636 10.7777 2.9012 9.70494 4.06442 8.98125C4.31193 7.05134 5.25422 5.27776 6.71494 3.99241C8.17566 2.70706 10.0547 1.99805 12.0004 1.99805C13.9461 1.99805 15.8252 2.70706 17.2859 3.99241C18.7466 5.27776 19.6889 7.05134 19.9364 8.98125C21.0996 9.70494 22.0045 10.7777 22.5217 12.0462C23.039 13.3148 23.1421 14.7144 22.8166 16.0451C22.491 17.3758 21.7533 18.5696 20.7088 19.4561C19.6643 20.3426 18.3664 20.8764 17.0004 20.9813V21.0003H7.00042V20.9813ZM13.0004 12.0003V8.00025H11.0004V12.0003H8.00042L12.0004 17.0003L16.0004 12.0003H13.0004Z"
                  fill="#032B3D"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>

      <div class="border-bottom">
        <svg
          v-if="active"
          width="207"
          height="3"
          viewBox="0 0 207 3"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <line
            x1="0.998047"
            y1="1.5"
            x2="206.508"
            y2="1.5"
            stroke="#28A4E2"
            stroke-width="2"
          />
        </svg>

        <svg
          v-else
          width="207"
          height="3"
          viewBox="0 0 207 3"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <line
            x1="0.998047"
            y1="1.5"
            x2="206.508"
            y2="1.5"
            stroke="#F7F9FB"
            stroke-width="2"
          />
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
import Avatar from "vue-avatar-component";
export default {
  name: "TimelineItem",
  components: {
    Avatar,
  },
  methods: {
    download(url) {
      location.href = url;
    },
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: true,
    },
    date: {
      type: String,
      required: true,
    },
    user: {
      type: Object,
      required: false,
    },
    file: {
      type: Object,
    },
    active: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style lang="scss">
.timeline-item {
  width: 236px;
  height: 95.01px;
  cursor: pointer;
  .border-bottom {
  }
  .border-left {
    margin-right: 10px;
  }
  .image-container {
    margin-right: 6px;
    .image {
      width: 50px;
      height: 50px;
      border-radius: 50%;
    }
  }
  .details {
    font-family: Manrope;
    font-style: normal;
    ._title {
      font-weight: bold;
      font-size: 13px;
      line-height: 18px;
      /* identical to box height */
      color: #5c5d63;
      &.active {
        color: #1679a8;
      }
    }
    .subtitle {
      font-weight: 500;
      font-size: 13px;
      min-height: 19px;
      line-height: 15px;
      color: #1679a8;
      overflow: hidden;
    }
    .date {
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: #a6a7ac;
      margin-bottom: 10.5px;
    }
  }
}
</style>