<template>
  <div class="timelinecomment" :id="id">
    <div class="_title">{{ names }}</div>
    <div class="datetime">{{ date_time | timeAgo }}</div>
    <div class="flex">
      <div class="my-border">
        <svg
          width="17"
          height="134"
          viewBox="0 0 17 134"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16.0672 62C16.0672 65.5143 12.6646 68.5 8.28362 68.5C3.90263 68.5 0.5 65.5143 0.5 62C0.5 58.4857 3.90263 55.5 8.28362 55.5C12.6646 55.5 16.0672 58.4857 16.0672 62Z"
            fill="#F2F6FA"
            stroke="#EAF1F8"
          />
          <ellipse
            cx="8.00704"
            cy="62.2334"
            rx="4.14181"
            ry="3.5"
            fill="#DEDEED"
          />
          <line
            v-if="!isLast"
            x1="7.50879"
            y1="74"
            x2="7.50879"
            y2="134"
            stroke="#E3E7EB"
            stroke-width="2"
          />
          <line
            x1="8.10059"
            x2="8.10059"
            y2="50"
            stroke="#E3E7EB"
            stroke-width="2"
          />
        </svg>
      </div>
      <div class="message flex custom-scroll">
        <div>
          <svg
            width="31"
            height="30"
            viewBox="0 0 31 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.30312 23.75L2.73438 28.125V5C2.73438 4.66848 2.86607 4.35054 3.10049 4.11612C3.33491 3.8817 3.65285 3.75 3.98438 3.75H26.4844C26.8159 3.75 27.1338 3.8817 27.3683 4.11612C27.6027 4.35054 27.7344 4.66848 27.7344 5V22.5C27.7344 22.8315 27.6027 23.1495 27.3683 23.3839C27.1338 23.6183 26.8159 23.75 26.4844 23.75H8.30312ZM8.98438 12.5V15H11.4844V12.5H8.98438ZM13.9844 12.5V15H16.4844V12.5H13.9844ZM18.9844 12.5V15H21.4844V12.5H18.9844Z"
              fill="#6E8691"
            />
          </svg>
        </div>
        {{ content }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TimelineCommentItem",
  props: {
    id: {
      type: String,
    },
    names: {
      type: String,
      required: true,
    },
    date_time: {
      type: String,
      required: true,
    },
    content: {
      type: String,
      required: false,
    },
    isLast: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style lang="scss">
.timelinecomment {
  width: 480.45px;
  height: 180px;
  margin-bottom: 5px;
  ._title {
    font-family: Manrope;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 18px;
    /* identical to box height */

    color: #5c5d63;
  }
  .datetime {
    font-family: Manrope;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 7px;
    color: #1679a8;
  }
  .message {
    width: 452.05px;
    max-height: 130px;
    height: fit-content;
    left: 718.4px;
    top: 359px;
    overflow-y: auto;
    background: #ffffff;
    padding: 10px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 15px;

    color: #032b3d;
    svg {
      margin-right: 10px;
    }
  }
  .my-border {
    margin-right: 10px;
  }
}

/* Portrait phones and smaller */
@media (max-width: 480px) {
  .timelinecomment {
    .message {
      width: 242.05px;
    }
  }
  .infos {
    .item {
      margin-right: 0px !important;
      margin-bottom: 12px;
    }
  }
}
</style>